import React, { useContext, Fragment } from "react"

/* Import Global Context(s) */
import ProjectsContext from "~context/projects"

/* Import Global Components */
import SEO from "~components/seo"
import Page from "~components/page"
import ProjectsTable from "~components/projectsTable/projectsTable"

/* Import Local Style(s) */
import "./projects.css"

const Projects = () => {
  const [projects] = useContext(ProjectsContext)
  return (
    <Fragment>
      <SEO title="Index" />
      <Page page="projects">
        {/* <Header isProjectsPage={true} /> */}
        <ProjectsTable projects={projects} />
        {/* <UpcomingProjects /> */}
      </Page>
    </Fragment>
  )
}

export default Projects
